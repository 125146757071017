































































































































































import {
  Vue, Watch, Inject, Component,
} from 'vue-property-decorator';
import Card from '@/components/material/Card.vue';
import MetadataEditor from '@/components/library-maintenance/metadata-editor.vue';
import { TableHeader } from '@/components/layout/models/table.d';
import ScreenHighlight from '@/components/screening-results/screen-highlight.vue';
import ScreenResultsService from '../services/screen-results-service';
import { ScreenResult, Hit } from '../models/screen-result.d';

@Component({
  components: {
    Card,
    MetadataEditor,
    ScreenHighlight,
  },
})
export default class ScreenResultView extends Vue {
  @Inject() ScreenResultsService!: ScreenResultsService

  screeningId?: string;

  screenResult: ScreenResult = {} as ScreenResult;

  booking = '';

  matchHeaders: TableHeader[] = [];

  showBooking = false;

  screenResultLoading = false;

  get hits(): Hit[] {
    if (this.screenResult.policies !== undefined) {
      return this.screenResult.policies.flatMap((x) => x.hits);
    }

    return [];
  }

  async mounted(): Promise<void> {
    this.matchHeaders = [
      {
        sortable: true,
        sortDirection: '',
        text: 'Keyword',
        value: 'keyword',
        width: '20%',
      },
      {
        sortable: true,
        sortDirection: '',
        text: 'Field',
        value: 'field',
        width: '20%',
      },
      {
        sortable: true,
        sortDirection: '',
        text: 'Text',
        value: 'text',
      },
      {
        sortable: true,
        sortDirection: '',
        text: 'Position',
        value: 'position',
        width: '20%',
      },
      {
        sortable: true,
        sortDirection: '',
        text: 'Length',
        value: 'length',
        width: '20%',
      },
    ];
  }

  get screeningDate(): string {
    if (this.screenResult['screening-date'] !== undefined) {
      return new Date(this.screenResult['screening-date']).toISOString();
    }

    return '';
  }

  @Watch('$route', { immediate: true })
  onRouteChange(value: any): Promise<void> {
    this.screeningId = value.params.id;

    if (!this.screeningId) return Promise.resolve();

    this.screenResultLoading = true;
    return this.ScreenResultsService
      .readSingle(this.screeningId)
      .then((screenResult) => {
        this.screenResult = screenResult;
        this.screenResultLoading = false;
      });
  }

    @Watch('showBooking', { immediate: true })
  onBookingRequested(): Promise<void> {
    if (!this.screeningId || !this.showBooking) return Promise.resolve();

    return this.ScreenResultsService
      .readScreening(this.screeningId)
      .then((booking) => {
        this.booking = booking;
      });
  }
}
