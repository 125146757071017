




















import {
  Vue, Prop, Component, Watch,
} from 'vue-property-decorator';
import { Hit } from '@/models/screen-result.d';
import formatScreening from '@/formatters/screening-hit-highlighter';

@Component
export default class ScreenHighlight extends Vue {
  @Prop({ default: () => ('') }) booking!: string;

  @Prop({ default: () => ({}) }) hits!: Hit[];

  jsonBooking = '';

  loadingBooking = true;

  @Watch('booking')
  async onBookingChange() {
    if (this.jsonBooking === '') {
      this.jsonBooking = await formatScreening(this.booking, this.hits);
      this.loadingBooking = false;
    }
  }
}
